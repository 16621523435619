// prettier-rule 'printWidth': 80

export default {
  development: {
    listOne: [
      { name: "Front-end development" },
      { name: "CMS implementation" },
      { name: "Interaction design" },
      { name: "eCommerce integration" },
    ],
    listTwo: [
      { name: "Content creation" },
      { name: "Digital strategy" },
      { name: "Search engine optimization (SEO)" },
      { name: "Hosting & maintenance" },
    ],
  },
  design: {
    listOne: [
      { name: "Web design" },
      { name: "User interface (UI) design" },
      { name: "Digital product design" },
      { name: "Content creation" },
    ],
    listTwo: [
      { name: "Email marketing" },
      { name: "User experience (UX) design" },
      { name: "App design" },
      { name: "Design systems" },
    ],
  },
  branding: {
    listOne: [
      { name: "Visual identity design" },
      { name: "Logo development" },
      { name: "Brand guidelines" },
      { name: "Pitch decks" },
      { name: "Campaign design" },
    ],
    listTwo: [
      { name: "Illustration" },
      { name: "Icon design" },
      { name: "Print & packaging" },
      { name: "Asset creation" },
      { name: "Motion graphics" },
    ],
  },
}
