import * as React from "react"
import { useContext } from "react"
import EmblaInfiniteCarousel from "../Carousels/InfCarousel/EmblaInfiniteCarousel"
import CursorContext from "../../context/cursorContext"

// Componenets
import { Section } from "../StyledComponents/layoutComponentStyles"
import carouselMedia from "./carouselMedia"

/* 









*/

const CarouselBanner: React.FunctionComponent = () => {
  const { setCursorState } = useContext(CursorContext)

  return (
    <div
      id='who-carousel'
      onMouseEnter={() => setCursorState?.("is-drag")}
      onMouseDown={() => setCursorState?.("is-drag is-dragging")}
      onMouseUp={() => setCursorState?.("is-drag")}
      onMouseLeave={() => setCursorState?.("")}
      role='slider'
      aria-valuenow={0}
      tabIndex={0}
    >
      <Section>
        <EmblaInfiniteCarousel slides={carouselMedia} />
      </Section>
    </div>
  )
}

export default CarouselBanner
