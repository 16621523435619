import { gsap, Power3 } from "gsap"
import { $desktop } from "../../utils/breakpoints"
import { $speedP2, $speedP3 } from "../animUtils"

export const studioWhoAnim = (el1: any, el2: any) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: "#who-banner",
        start: () => {
          if ($desktop) {
            return "0px 60%"
          } else {
            return "0px 80%"
          }
        },
        toggleActions: "play none none none",
      },
    })
    .from("#who-accent", { opacity: 0, duration: $speedP3, ease: "expo.out" })
    .from(
      el1,
      {
        y: "200%",
        skewY: "10%",
        duration: $speedP3,
        ease: "expo.out",
        stagger: 0.2,
      },
      `-=${$speedP2}`
    )
    .from(
      el2,
      {
        y: "100%",
        opacity: 0,
        duration: $speedP3,
        ease: "expo.out",
        stagger: 0.05,
      },
      `-=${$speedP3}`
    )
}

export const studioBackgroundDesktopAnim = () => {
  return gsap.fromTo(
    [".who-section", "#who-carousel", ".c-studio-what"],
    { backgroundColor: "rgba(219, 219, 219, 1)" },
    {
      scrollTrigger: {
        trigger: "#who-carousel",
        start: "0px 55%",
        end: "0px 20%",
        scrub: true,
      },
      backgroundColor: "rgba(219, 219, 219, 0)",
      ease: "linear",
    }
  )
}

export const studioBackgroundTabletMobileAnim = () => {
  return gsap.fromTo(
    [".who-section", "#who-carousel", ".c-studio-what"],
    { backgroundColor: "rgba(219, 219, 219, 1)" },
    {
      scrollTrigger: {
        trigger: "#who-carousel",
        start: "0px 70%",
        end: "0px 35%",
        scrub: 0.5,
      },
      backgroundColor: "rgba(219, 219, 219, 0)",
      ease: "linear",
    }
  )
}

export const howDividerAnim = (lineLeft: any, subtitle: any, lineRight: any) => {
  return gsap
    .timeline({
      scrollTrigger: {
        trigger: ".c-studio-what__divider",
        start: "0px 87.5%",
        toggleActions: "play none none none",
      },
    })
    .from(lineLeft, { scaleX: 0, duration: $speedP2, ease: Power3.easeIn })
    .from(subtitle, { y: "100%", duration: $speedP2, ease: "expo.out" })
    .from(lineRight, { scaleX: 0, duration: $speedP2, ease: "expo.out" }, "-=0.3")
}

export const createServiceTimeline = (
  trigger: any,
  indicator: any,
  indicatorLine: any,
  title: any,
  titleLine: any,
  par: any,
  listOne: any,
  listTwo: any
) => {
  return gsap
    .timeline({
      scrollTrigger: {
        trigger: trigger,
        start: "0px 62.5%",
        toggleActions: "play none none none",
      },
    })
    .from(indicator, {
      y: "200%",
      duration: $speedP2,
      ease: "expo.out",
    })
    .from(
      indicatorLine,
      {
        scaleX: 0,
        duration: $speedP2,
        ease: "expo.out",
      },
      "-=0.5"
    )
    .from(
      title,
      {
        y: "200%",
        duration: $speedP2,
        ease: "expo.out",
      },
      "-=0.5"
    )
    .from(
      titleLine,
      {
        scaleX: 0,
        duration: $speedP2,
        ease: "expo.out",
      },
      "-=0.5"
    )
    .from(
      par,
      {
        y: "300%",
        skewY: "10%",
        duration: $speedP2,
        ease: "expo.out",
        stagger: 0.125,
      },
      "-=0.5"
    )
    .from(
      listOne,
      {
        y: "200%",
        skewY: "10%",
        duration: $speedP2,
        ease: "expo.out",
        stagger: 0.1,
      },
      `-=${$speedP2}`
    )
    .from(
      listTwo,
      {
        y: "200%",
        skewY: "10%",
        duration: $speedP2,
        ease: "expo.out",
        stagger: 0.1,
      },
      `-=${$speedP3}`
    )
}

export const bannerSlideAnim = () => {
  return gsap.from(".c-why-banner-img", {
    width: 0,
    duration: $speedP3,
    ease: "expo.inOut",
    scrollTrigger: {
      trigger: ".c-why-banner-img",
      start: "0px 90%",
      // markers: true,
      toggleActions: "play none none none",
    },
  })
}

export const whyImageAnim = (el: any, trigger: any) => {
  return gsap.from(el, {
    height: 0,
    duration: $speedP3,
    ease: "expo.inOut",
    scrollTrigger: {
      trigger: trigger,
      start: "0px 62.5%",
      // markers: true,
      toggleActions: "play none none none",
    },
  })
}

export const whyContentAnim = (el1: any, el2: any) => {
  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".c-why-content",
        start: "0px 90%",
        toggleActions: "play none none none",
      },
    })
    .from("#why-accent", { opacity: 0, duration: $speedP3, ease: "expo.out" })
    .from(
      el1,
      {
        y: "200%",
        skewY: "10%",
        duration: $speedP3,
        ease: "expo.out",
        stagger: 0.2,
      },
      `-=${$speedP2}`
    )
    .from(
      el2,
      {
        y: "100%",
        opacity: 0,
        duration: $speedP3,
        ease: "expo.out",
        stagger: 0.05,
      },
      `-=${$speedP3}`
    )
}

export const prevClientTitleAnim = (el: any, trigger: any) => {
  return gsap
    .timeline({
      scrollTrigger: {
        trigger: trigger,
        markers: false,
        start: "0px 65%",
        toggleActions: "play none none none",
      },
    })
    .from(el, {
      y: "200%",
      skewY: "10%",
      duration: $speedP3,
      ease: "expo.out",
      stagger: 0.2,
    })
}

export const clientLogoAnim = (elem: any) => {
  return gsap.from(elem, {
    y: "100%",
    opacity: 0,
    duration: $speedP2,
    ease: "expo.out",
    stagger: 0.15,
    scrollTrigger: {
      trigger: ".c-prev-clients__grid",
      start: "0px 65%",
      markers: false,
      toggleActions: "play none none none",
    },
  })
}
