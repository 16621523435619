import * as React from "react"
import { useRef, useEffect } from "react"

// Components
import data from "./listData"

// Animations
import { SplitText } from "gsap/SplitText"

// Helpers
import { collectListItems } from "./StudioWhat"
import { createServiceTimeline } from "../../animations/pages/studioAnim"
import { checkParSize } from "./StudioWhat"

/*









*/

const ServiceOne: React.FunctionComponent = () => {
  // Refs
  const triggerRef = useRef<HTMLDivElement>(null)
  const indicatorRef = useRef<HTMLHeadingElement>(null)
  const indicatorLineRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLHeadingElement>(null)
  const titleLineRef = useRef<HTMLDivElement>(null)
  const parRef = useRef<HTMLHeadingElement>(null)

  // Effects
  useEffect(() => {
    const listOne = collectListItems("shsl_1")
    const listTwo = collectListItems("shsl_2")

    if (document.fonts) {
      document.fonts.ready.then(() => {
        const splitInner = new SplitText(parRef.current, {
          type: "lines",
        })
        new SplitText(parRef.current, {
          type: "lines",
          linesClass: "u-overflow-reveal",
        })
        return createServiceTimeline(
          triggerRef.current,
          indicatorRef.current,
          indicatorLineRef.current,
          titleRef.current,
          titleLineRef.current,
          splitInner.lines,
          listOne,
          listTwo
        )
      })
    } else {
      setTimeout(() => {
        const splitInner = new SplitText(parRef.current, {
          type: "lines",
        })
        new SplitText(parRef.current, {
          type: "lines",
          linesClass: "u-overflow-reveal",
        })
        return createServiceTimeline(
          triggerRef.current,
          indicatorRef.current,
          indicatorLineRef.current,
          titleRef.current,
          titleLineRef.current,
          splitInner.lines,
          listOne,
          listTwo
        )
      }, 200)
    }
  }, [])

  return (
    <div className='c-studio-what__service' ref={triggerRef}>
      <div className='c-studio-what-service__index'>
        <div className='c-studio-what-service__heading-container'>
          <h4 ref={indicatorRef}>01</h4>
          <div className='o-line' ref={indicatorLineRef}></div>
        </div>
      </div>
      <div className='c-studio-what-service__content'>
        <div className='c-studio-what-service__heading-container'>
          <h4 ref={titleRef}>Web development</h4>
          <div className='o-line' ref={titleLineRef}></div>
        </div>
        <p className='o-par-lrg' ref={parRef}>
          Your online presence is your most important asset and helping you build it is at the heart of what we do. Creative
          development is what makes us tick, with the emphasis on <i>'creative'</i>, in order to help tell meaningful stories through
          delightful interaction and human connection.
        </p>
        <div className='c-studio-what-service__list-container'>
          <ul id='shsl_1'>
            {data.development.listOne.map(({ name }, index) => (
              <li key={index}>
                <p className={checkParSize()}>{name}</p>
              </li>
            ))}
          </ul>
          <ul id='shsl_2'>
            {data.development.listTwo.map(({ name }, index) => (
              <li key={index}>
                <p className={checkParSize()}>{name}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ServiceOne
