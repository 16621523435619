import * as React from "react"
import { useRef, useEffect } from "react"

// Components
import data from "./listData"

// Animations
import { SplitText } from "gsap/SplitText"

// Helpers
import { collectListItems } from "./StudioWhat"
import { createServiceTimeline } from "../../animations/pages/studioAnim"
import { checkParSize } from "./StudioWhat"

/*









*/

const ServiceThree: React.FunctionComponent = () => {
  // Refs
  const triggerRef = useRef<HTMLDivElement>(null)
  const indicatorRef = useRef<HTMLHeadingElement>(null)
  const indicatorLineRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLHeadingElement>(null)
  const titleLineRef = useRef<HTMLDivElement>(null)
  const parRef = useRef<HTMLHeadingElement>(null)

  // Effects
  useEffect(() => {
    const listOne = collectListItems("shsl_5")
    const listTwo = collectListItems("shsl_6")

    if (document.fonts) {
      document.fonts.ready.then(() => {
        const splitInner = new SplitText(parRef.current, {
          type: "lines",
        })
        new SplitText(parRef.current, {
          type: "lines",
          linesClass: "u-overflow-reveal",
        })
        return createServiceTimeline(
          triggerRef.current,
          indicatorRef.current,
          indicatorLineRef.current,
          titleRef.current,
          titleLineRef.current,
          splitInner.lines,
          listOne,
          listTwo
        )
      })
    } else {
      setTimeout(() => {
        const splitInner = new SplitText(parRef.current, {
          type: "lines",
        })
        new SplitText(parRef.current, {
          type: "lines",
          linesClass: "u-overflow-reveal",
        })
        return createServiceTimeline(
          triggerRef.current,
          indicatorRef.current,
          indicatorLineRef.current,
          titleRef.current,
          titleLineRef.current,
          splitInner.lines,
          listOne,
          listTwo
        )
      }, 200)
    }
  }, [])

  return (
    <div className='c-studio-what__service' ref={triggerRef}>
      <div className='c-studio-what-service__index'>
        <div className='c-studio-what-service__heading-container'>
          <h4 ref={indicatorRef}>03</h4>
          <div className='o-line' ref={indicatorLineRef}></div>
        </div>
      </div>
      <div className='c-studio-what-service__content'>
        <div className='c-studio-what-service__heading-container'>
          <h4 ref={titleRef}>Brand Identity</h4>
          <div className='o-line' ref={titleLineRef}></div>
        </div>
        <p className='o-par-lrg' ref={parRef}>
          Your identity is not just a logo, it's a gut response; a feeling towards everything you stand for. We help brands and
          businesses to show their true colours by creating charming and thoughtful visual identities that promote cross-platform
          continuity and consumer buy-in.
        </p>
        <div className='c-studio-what-service__list-container '>
          <ul id='shsl_5'>
            {data.branding.listOne.map(({ name }, index) => (
              <li key={index}>
                <p className={checkParSize()}>{name}</p>
              </li>
            ))}
          </ul>
          <ul id='shsl_6'>
            {data.branding.listTwo.map(({ name }, index) => (
              <li key={index}>
                <p className={checkParSize()}>{name}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ServiceThree
