/**
 * A series of helper functions, methods and variables used across the website
 *
 *  [1]. waitForElement function
 *
 *
 */

/*
 
 
 
 
 
 
 
 
 
 */

// [1]. waitForElement function

/**
 * Function that waits for an element before resolving a promise
 *
 * @param {String} querySelector - Selector of element to wait for
 * @param {Integer} timeout - Milliseconds to wait before timing out, or 0 for no timeout
 */

export const waitForElement = (querySelector: string, timeout = 0) => {
  const startTime = new Date().getTime()
  return new Promise<void>((resolve, reject) => {
    const timer = setInterval(() => {
      const now = new Date().getTime()
      if (document.querySelector(querySelector)) {
        clearInterval(timer)
        resolve()
      } else if (timeout && now - startTime >= timeout) {
        clearInterval(timer)
        reject()
      }
    }, 100)
  })
}
