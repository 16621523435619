import * as React from "react"

// Components
import { Container, Section } from "../StyledComponents/layoutComponentStyles"
import ScrollPrompt from "../ScrollPrompt"
import { $mobile } from "../../utils/breakpoints"

// Animations
import {
  delayverticalTagline,
  delayedFade,
  delayedslideTitleParent,
  slideTitleChild,
  greyBackground,
} from "../../animations/globalVariants"
import { motion } from "framer-motion"

// Interfaces
interface StudioHeroProps {}

/*









*/

const StudioHero: React.FunctionComponent<StudioHeroProps> = () => {
  return (
    <Section fullHeight alignCenter initial='initial' animate='animate' exit='exit' variants={greyBackground}>
      <Container sixteenClm fullHeight>
        {!$mobile ? <StudioTitleDesktopTablet /> : <StudioTitleMobile />}

        <div className='o-vertical-tagline'>
          <motion.span initial='initial' animate='animate' exit='exit' variants={delayverticalTagline}>
            The pleasure is all ours
          </motion.span>
        </div>
        <ScrollPrompt variants={delayedFade} />
      </Container>
    </Section>
  )
}

export default StudioHero

export const StudioTitleDesktopTablet: React.FunctionComponent<StudioHeroProps> = () => {
  return (
    <motion.div
      initial='initial'
      animate='animate'
      exit='exit'
      variants={delayedslideTitleParent}
      className='o-hero-title-wrapper -responsive'
    >
      <div className='o-hero-title -studio'>
        <div className='u-overflow-reveal'>
          <motion.h3 variants={slideTitleChild}>
            An <span>independant</span> and{" "}
            <span>
              ethical
              {window.innerWidth > 480 ? <br /> : " "}
            </span>
          </motion.h3>
        </div>
        <div className='u-overflow-reveal'>
          <motion.h3 variants={slideTitleChild}>multidisciplinary design studio</motion.h3>
        </div>
      </div>
    </motion.div>
  )
}

export const StudioTitleMobile: React.FunctionComponent<StudioHeroProps> = () => {
  return (
    <motion.div
      initial='initial'
      animate='animate'
      exit='exit'
      variants={delayedslideTitleParent}
      className='o-hero-title-wrapper -responsive'
    >
      <div className='o-hero-title -studio'>
        <div className='u-overflow-reveal'>
          <motion.h3 variants={slideTitleChild}>
            An <span>independant</span>
          </motion.h3>
        </div>
        <div className='u-overflow-reveal'>
          <motion.h3 variants={slideTitleChild}>
            and <span>ethical</span>
          </motion.h3>
        </div>
        <div className='u-overflow-reveal'>
          <motion.h3 variants={slideTitleChild}>multidisciplinary</motion.h3>
        </div>
        <div className='u-overflow-reveal'>
          <motion.h3 variants={slideTitleChild}>design studio</motion.h3>
        </div>
      </div>
    </motion.div>
  )
}
