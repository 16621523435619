import * as React from "react"
import { useRef, useEffect } from "react"

// Components
import data from "./listData"

// Animations
import { SplitText } from "gsap/SplitText"

// Helpers
import { collectListItems } from "./StudioWhat"
import { createServiceTimeline } from "../../animations/pages/studioAnim"
import { checkParSize } from "./StudioWhat"

/*









*/

const ServiceTwo: React.FunctionComponent = () => {
  // Refs
  const triggerRef = useRef<HTMLDivElement>(null)
  const indicatorRef = useRef<HTMLHeadingElement>(null)
  const indicatorLineRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLHeadingElement>(null)
  const titleLineRef = useRef<HTMLDivElement>(null)
  const parRef = useRef<HTMLHeadingElement>(null)

  // Effects
  useEffect(() => {
    const listOne = collectListItems("shsl_3")
    const listTwo = collectListItems("shsl_4")

    if (document.fonts) {
      document.fonts.ready.then(() => {
        const splitInner = new SplitText(parRef.current, {
          type: "lines",
        })
        new SplitText(parRef.current, {
          type: "lines",
          linesClass: "u-overflow-reveal",
        })
        return createServiceTimeline(
          triggerRef.current,
          indicatorRef.current,
          indicatorLineRef.current,
          titleRef.current,
          titleLineRef.current,
          splitInner.lines,
          listOne,
          listTwo
        )
      })
    } else {
      setTimeout(() => {
        const splitInner = new SplitText(parRef.current, {
          type: "lines",
        })
        new SplitText(parRef.current, {
          type: "lines",
          linesClass: "u-overflow-reveal",
        })
        return createServiceTimeline(
          triggerRef.current,
          indicatorRef.current,
          indicatorLineRef.current,
          titleRef.current,
          titleLineRef.current,
          splitInner.lines,
          listOne,
          listTwo
        )
      }, 200)
    }
  }, [])

  return (
    <div className='c-studio-what__service' ref={triggerRef}>
      <div className='c-studio-what-service__index'>
        <div className='c-studio-what-service__heading-container'>
          <h4 ref={indicatorRef}>02</h4>
          <div className='o-line' ref={indicatorLineRef}></div>
        </div>
      </div>
      <div className='c-studio-what-service__content'>
        <div className='c-studio-what-service__heading-container'>
          <h4 ref={titleRef}>UI/UX Design</h4>
          <div className='o-line' ref={titleLineRef}></div>
        </div>
        <p className='o-par-lrg' ref={parRef}>
          From conception to completion, we bring ideas to life through our design work. The result is beautifully designed, responsive
          experiences, that strike the perfect balance between functionality and fun.
        </p>
        <div className='c-studio-what-service__list-container '>
          <ul id='shsl_3'>
            {data.design.listOne.map(({ name }, index) => (
              <li key={index}>
                <p className={checkParSize()}>{name}</p>
              </li>
            ))}
          </ul>
          <ul id='shsl_4'>
            {data.design.listTwo.map(({ name }, index) => (
              <li key={index}>
                <p className={checkParSize()}>{name}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ServiceTwo
