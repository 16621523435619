import * as React from "react"
import { useEffect, useContext } from "react"
import ReactGA from "react-ga"

// Context
import ScrollSafeContext from "../context/scrollSafeContext"

// Componenets
import SEO from "../components/seo"
import StudioHero from "../components/StudioHero"
import StudioWho from "../components/StudioWho"
import StudioCarouselBanner from "../components/StudioCarousel"
import StudioHow from "../components/StudioWhat"
import StudioWhy from "../components/StudioWhy"
import StudioPrevClients from "../components/StudioPrevClients"

// Animations
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { $mobile } from "../utils/breakpoints"

// Interfaces
interface IndexPageProps {}

// SEO
const seo = {
  title: "Studio",
  description:
    "Silk Studio proudly designs, develops and delivers original, functional and responsible solutions in web development, UI/UX design and brand identity.",
  keywords: [
    "digital design studio",
    "web design shrewsbury",
    "web design midlands",
    "freelance web developer",
    "brand design uk",
    "freelance brand design",
  ],
}

/*









*/

const StudioPage: React.FunctionComponent<IndexPageProps> = () => {
  // Context
  const { scrollSafe, setScrollSafe } = useContext(ScrollSafeContext)

  // Effects
  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh()
      setScrollSafe?.(true)
    }, 600)

    ReactGA.pageview(window.location.pathname + window.location.search)

    return () => {
      setScrollSafe?.(false)
    }
  }, [])

  return (
    <>
      <SEO title={seo.title} description={seo.description} keywords={seo.keywords} />
      {scrollSafe && (
        <>
          <StudioHero />
          <StudioWho />
          <StudioCarouselBanner />
          <StudioHow />
          <StudioWhy />
          {!$mobile && <StudioPrevClients />}
        </>
      )}
    </>
  )
}

export default StudioPage
