import * as React from "react"
import { useEffect } from "react"
import { useEmblaCarousel } from "embla-carousel/react"

// Styles
import "./_infinite-carousel.scss"

// Components
import ProgressiveImage from "react-progressive-image"

// @types
import { Slide } from "../../../@types/global-types"
import { $mobile } from "../../../utils/breakpoints"

interface EmblaInfiniteCarouselProps {
  slides: Array<Slide>
}

/*









*/

const EmblaInfiniteCarousel: React.FunctionComponent<EmblaInfiniteCarouselProps> = ({ slides }) => {
  const options = {
    dragFree: true,
    loop: true,
    speed: 0.1,
    inViewThreshold: 0.99,
    startIndex: !$mobile ? 2 : 6,
  }
  const [EmblaCarouselReact, embla] = useEmblaCarousel(options)

  useEffect(() => {
    if (embla) {
      // Embla API is ready
    }
  }, [embla])

  return (
    <EmblaCarouselReact className='c-inf-carousel__viewport'>
      <div className='c-inf-carousel__container'>
        <CarouselInner slides={slides} />
      </div>
    </EmblaCarouselReact>
  )
}

export default EmblaInfiniteCarousel

export const CarouselInner: React.FunctionComponent<EmblaInfiniteCarouselProps> = ({ slides }) => {
  return (
    <>
      {slides.map((slide, index) => {
        if (typeof slide.source === "object") {
          return (
            <div className='c-inf-carousel__slide' key={slide.key} aria-label={`${index + 1} of ${slides.length}`}>
              <ProgressiveImage src={slide.source.hiRes} placeholder={slide.source.lowRes}>
                {(src: string) => <img src={src} alt={slide.altText} className='c-inf-carousel__content' />}
              </ProgressiveImage>
            </div>
          )
        } else if (typeof slide.source === "string") {
          return (
            <div className='c-inf-carousel__slide' key={slide.key} aria-label={`${index + 1} of ${slides.length}`}>
              <video src={slide.source} autoPlay muted loop playsInline className='c-inf-carousel__content' itemType='video/mp4' />
            </div>
          )
        }
      })}
    </>
  )
}
