import * as React from "react"
import { useEffect, useRef } from "react"
import { $desktop } from "../../utils/breakpoints"

//Styles
import "./_studio-who.scss"

// Componenets
import { Section, Container, FlexRow } from "../StyledComponents/layoutComponentStyles"
import ProgressiveImage from "react-progressive-image"
import who_square_1 from "../../assets/images/who_square_1.jpg"
import who_square_2 from "../../assets/images/who_square_2.jpg"
import { waitForElement } from "../../helpers/globalHelperFunctions"

// Animation
import { studioBackgroundDesktopAnim, studioBackgroundTabletMobileAnim } from "../../animations/pages/studioAnim"
import { galleryScrub } from "../../animations/pages/homeAnim"
import { studioWhoAnim } from "../../animations/pages/studioAnim"
import { SplitText } from "gsap/SplitText"
import { motion } from "framer-motion"
import { pageTransitionInstant } from "../../animations/globalVariants"

/*









*/

const StudioWho: React.FunctionComponent = () => {
  // Refs
  const headingRef = useRef<HTMLHeadingElement>(null)
  const parRef = useRef<HTMLParagraphElement>(null)

  waitForElement("#studio_who_par", 0)
    .then(() => {
      const splitTitleInner = new SplitText(headingRef.current, {
        type: "words,lines",
      })
      new SplitText(headingRef.current, {
        type: "words,lines",
        linesClass: "u-overflow-reveal",
      })
      const splitPar = new SplitText(parRef.current, { type: "lines" })

      studioWhoAnim(splitTitleInner.lines, splitPar.lines)
    })
    .catch(() => {
      alert("element did not load in 3 seconds")
    })

  // Init gsap animations
  useEffect(() => {
    if ($desktop) {
      studioBackgroundDesktopAnim()
    } else {
      studioBackgroundTabletMobileAnim()
    }

    return () => {}
  }, [])

  const onAnimComplete = () => {
    const thumbnails: Array<HTMLElement> = Array.from(document.querySelectorAll(".who-img__asset"))
    thumbnails.forEach(thumbnail => {
      return galleryScrub(thumbnail)
    })
  }

  return (
    <motion.div
      initial='initial'
      animate='animate'
      exit='exit'
      variants={pageTransitionInstant}
      className='who-section'
      onAnimationComplete={onAnimComplete}
    >
      <Section>
        <Container fourteenClm>
          <FlexRow justifySpaceBetween mobileDirColumn>
            <div className='who-img one'>
              <ProgressiveImage src={who_square_1} placeholder={who_square_1}>
                {(src: string) => <img src={src} alt='placeholder' className='who-img__asset' />}
              </ProgressiveImage>
            </div>
            <div className='who-img two'>
              <ProgressiveImage src={who_square_2} placeholder={who_square_2}>
                {(src: string) => <img src={src} alt='placeholder' className='who-img__asset' />}
              </ProgressiveImage>
            </div>
          </FlexRow>
        </Container>
        <Container fourteenClm>
          <div className='o-break-banner -spaced' id='who-banner'>
            <div className='o-break-banner__sml-clm'>
              <div className='o-accent' id='who-accent'>
                01. Let's set the scene
              </div>
              <h4 ref={headingRef}>Digital creativity with a human touch</h4>
            </div>
            <div className='o-break-banner__par'>
              <p ref={parRef} className='o-par-lrg' id='studio_who_par'>
                Silk Studio is a UK based, independent & ethical, multi-disciplinary design studio, created by Nathan Hughes{" "}
                <em style={{ display: "inline-block" }}>(try saying that five times fast)</em>. Specialising in front-end development,
                UI/UX design and branding, we are committed to making original, functional and responsible work that helps brands and
                businesses reach the people that matter most to them.
                <br />
                <br />
                With over six years of experience in both the freelance and agency sectors, we provide top-level expertise across the
                creative spectrum to an ever-growing list of clients. Furthermore, we pride ourselves on our playful innovation and
                virtuous practice as we continue to create genuine, honest work for genuine, honest clients.
              </p>
            </div>
          </div>
        </Container>
      </Section>
    </motion.div>
  )
}

export default StudioWho
