import * as React from "react"
import { useEffect, useRef } from "react"

// Styles
import "./studio-what.scss"

// Components
import ServiceOne from "./ServiceOne"
import ServiceTwo from "./ServiceTwo"
import ServiceThree from "./ServiceThree"
import { Section, Container } from "../StyledComponents/layoutComponentStyles"

// Animation
import { createTriggerRevealTitle, createTriggerSlideText } from "../../animations/globalAnims"
import { howDividerAnim } from "../../animations/pages/studioAnim"
import { $mobile } from "../../utils/breakpoints"
import { pageTransitionInstant } from "../../animations/globalVariants"

// Helper Functions
export const collectListItems = (id: string) => {
  let items: Array<any> = []
  const list: Array<HTMLElement> = Array.from(document.getElementById(id)?.children as HTMLCollectionOf<HTMLElement>)

  list.forEach(item => {
    items.push(Array.from(item.children))
  })

  return items
}

export const checkParSize = () => {
  if (!$mobile) {
    return undefined
  } else {
    return "o-par-sml"
  }
}

/*




 




*/

const StudioHow: React.FunctionComponent = () => {
  // Refs
  const lineLeftRef = useRef<HTMLDivElement>(null)
  const subtitleRef = useRef<HTMLParagraphElement>(null)
  const lineRightRef = useRef<HTMLDivElement>(null)
  const overviewParRef = useRef<HTMLHeadingElement>(null)

  // Effects
  useEffect(() => {
    createTriggerRevealTitle(".c-studio-what__header")
    howDividerAnim(lineLeftRef.current, subtitleRef.current, lineRightRef.current)
    createTriggerSlideText(overviewParRef.current, 0.3)
  }, [])

  return (
    <Section initial='initial' animate='animate' exit='exit' variants={pageTransitionInstant}>
      <div className='c-studio-what'>
        <Container fourteenClm>
          <div className='c-studio-what__header'>
            <h1>No two projects</h1>
            <h1>are the same</h1>
          </div>
        </Container>
        <Container sixteenClm>
          <div className='c-studio-what__divider'>
            <div ref={lineLeftRef} className='o-line'></div>
            <div className='u-overflow-reveal'>
              <p className='o-accent' ref={subtitleRef}>
                02. What's our secret...?
              </p>
            </div>

            <div ref={lineRightRef} className='o-line'></div>
          </div>
        </Container>
        <Container sixteenClm>
          <div className='c-studio-what__overview'>
            <h4 ref={overviewParRef}>
              We believe that good brands deserve good design, which is why we strive to partner with like-minded creative and
              design-led organisations to produce work they are justifiably proud of. Weaving craft with strategy, we endeavour to
              create bespoke solutions with broad appeal that help consumers see your brand the way you do.
            </h4>
          </div>
        </Container>

        <Container sixteenClm>
          <div className='c-studio-what__services'>
            <ServiceOne />
            <ServiceTwo />
            <ServiceThree />
          </div>
        </Container>
      </div>
    </Section>
  )
}

export default StudioHow
