import * as React from "react"
import { useEffect, useRef } from "react"
import styled from "styled-components"

// Styles
import "./studio-why.scss"

// Componenets
import why_banner from "../../assets/images/why_banner.jpg"
import why_portrait from "../../assets/images/why_portrait.jpg"
import { Container, FlexRow, Section } from "../StyledComponents/layoutComponentStyles"
import ProgressiveImage from "react-progressive-image"

// Animation
import { bannerSlideAnim, whyContentAnim, whyImageAnim } from "../../animations/pages/studioAnim"
import { galleryScrub } from "../../animations/pages/homeAnim"
import { SplitText } from "gsap/SplitText"
import { $mobile } from "../../utils/breakpoints"

// Styled Components
const WhyImageAnimContainer = styled.div`
  height: 53rem;
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    height: 75rem;
  }

  @media screen and (max-width: 480px) {
    height: 131.25rem;
  }
`

const WhyImageContainer = styled.div`
  height: 53rem;
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    height: 75rem;
  }

  @media screen and (max-width: 480px) {
    height: 131.25rem;
  }
`

// Interfaces
interface StudioWhyProps {}

/*









*/

const StudioWhy: React.FunctionComponent<StudioWhyProps> = () => {
  // Refs
  const headingRef = useRef<HTMLHeadingElement>(null)
  const parRef = useRef<HTMLParagraphElement>(null)
  const bannerImgRef = useRef<HTMLImageElement>(null)
  const sectionImgRef = useRef<HTMLImageElement>(null)
  const imgContainerRef = useRef<any>(null)
  const imgAnimContainerRef = useRef<any>(null)

  useEffect(() => {
    if (document.fonts) {
      document.fonts.ready.then(() => {
        const splitTitleInner = new SplitText(headingRef.current, {
          type: "words,lines",
        })
        new SplitText(headingRef.current, {
          type: "words,lines",
          linesClass: "u-overflow-reveal",
        })
        const splitPar = new SplitText(parRef.current, { type: "lines" })
        whyContentAnim(splitTitleInner.lines, splitPar.lines)
      })
    } else {
      setTimeout(() => {
        const splitTitleInner = new SplitText(headingRef.current, {
          type: "words,lines",
        })
        new SplitText(headingRef.current, {
          type: "words,lines",
          linesClass: "u-overflow-reveal",
        })
        const splitPar = new SplitText(parRef.current, { type: "lines" })
        whyContentAnim(splitTitleInner.lines, splitPar.lines)
      }, 500)
    }

    if (!$mobile) {
      bannerSlideAnim()
      galleryScrub(bannerImgRef.current)
    }

    whyImageAnim(imgContainerRef.current, imgAnimContainerRef.current)
    galleryScrub(sectionImgRef.current)
  }, [])

  return (
    <>
      {!$mobile && (
        <Section>
          <div className='c-why-banner-img'>
            <ProgressiveImage src={why_banner} placeholder={why_banner}>
              {(src: string) => <img src={src} alt='placeholder' ref={bannerImgRef} />}
            </ProgressiveImage>
          </div>
        </Section>
      )}

      <Section fullHeight={!$mobile ? true : false}>
        <div className='c-why-section'>
          <Container sixteenClm fullHeight>
            <FlexRow justifySpaceBetween mobileDirColumn style={{ width: "100%", height: "100%" }}>
              <WhyImageAnimContainer ref={imgAnimContainerRef}>
                <WhyImageContainer ref={imgContainerRef}>
                  <div className='c-why-img'>
                    <ProgressiveImage src={why_portrait} placeholder={why_portrait}>
                      {(src: string) => <img src={src} alt='placeholder' ref={sectionImgRef} />}
                    </ProgressiveImage>
                  </div>
                </WhyImageContainer>
              </WhyImageAnimContainer>

              <div className='c-why-content'>
                <p className='o-accent' id='why-accent'>
                  03. Our north star
                </p>
                <h4 ref={headingRef}>Good, honest work for good, honest people</h4>
                <p ref={parRef}>
                  At a time where the digital and design industries are completely over-saturated with price-competitive agencies and
                  pay-per-hour freelancers in a race to the bottom, many businesses now simply just want design excellence which goes
                  onto deliver results. Offering a creative service with a human touch, we provide long-term creative guardianship as
                  well as expertly designed collateral.
                  <br />
                  <br />
                  We put great value on our ethical practice and always aim to produce good, honest work for good, honest clients.
                  We'll never charge our clients for something we think they don't need and as a small independent studio, we don't
                  have the big agency bureaucracy or overheads, meaning your money is spent on what really matters: creating great
                  work.
                </p>
              </div>
            </FlexRow>
          </Container>
        </div>
      </Section>
    </>
  )
}

export default StudioWhy
