import * as React from "react"
import { useEffect, useRef } from "react"

// Styles
import "./_studio-prev-clients.scss"

// Componenets
import { logos } from "../../assets/images/client-logos/logos"
import { Section, Container } from "../StyledComponents/layoutComponentStyles"
import { prevClientTitleAnim, clientLogoAnim } from "../../animations/pages/studioAnim"
import { createTriggerRevealTitle, createTriggerSlideText } from "../../animations/globalAnims"
import { SplitText } from "gsap/SplitText"

// Animations

// Interfaces
interface StudioPrevClientsProps {}

/*









*/

const StudioPrevClients: React.FunctionComponent<StudioPrevClientsProps> = () => {
  const titleRef = useRef<HTMLHeadingElement>(null)
  const parRef = useRef<HTMLParagraphElement>(null)
  const logosRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    createTriggerRevealTitle(titleRef.current)
    createTriggerSlideText(parRef.current, 0.3)

    const logoArr: Array<Element> = Array.from(logosRef?.current!.children)

    clientLogoAnim(logoArr)
  }, [])

  return (
    <Section>
      <Container sixteenClm>
        <div className='c-prev-clients'>
          <div>
            <h2 ref={titleRef}>
              No job too big,
              <br /> no idea too small
            </h2>
            <p ref={parRef}>
              We work with clients little and large, from the boutique start-ups to the big-league enterprises. Whether you're an
              industry leader or the new kids on the block, we can help turn your ideas into realities.
            </p>
          </div>

          <div className='c-prev-clients__grid' ref={logosRef}>
            {logos.map((logo: any) => (
              <img key={logo.key} src={logo.svg} alt={logo.alt} />
            ))}
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default StudioPrevClients

// className='o-par-lrg'
