// Media
import media_01_posters from "../../assets/carousel_media/01_posters/option-2.jpg"
import media_02_typing from "../../assets/carousel_media/02_typing.mp4"
import media_03_plant from "../../assets/carousel_media/03_plant.jpg"
import media_04_ipad from "../../assets/carousel_media/04_iPad.jpg"
import media_05_iphone from "../../assets/carousel_media/05_iPhone.jpg"
import media_06_scrolling from "../../assets/carousel_media/06_scrolling.mp4"
import media_07_desk from "../../assets/carousel_media/07_desk.jpg"
import media_08_plant from "../../assets/carousel_media/08_plant.jpg"

import { Slide } from "../../@types/global-types"

const carouselMedia: Array<Slide> = [
  { source: { lowRes: media_01_posters, hiRes: media_01_posters }, altText: "placeholder", key: 1 },
  { source: media_02_typing, altText: "placeholder", key: 2 },
  { source: { lowRes: media_03_plant, hiRes: media_03_plant }, altText: "placeholder", key: 3 },
  { source: { lowRes: media_04_ipad, hiRes: media_04_ipad }, altText: "placeholder", key: 4 },
  { source: { lowRes: media_05_iphone, hiRes: media_05_iphone }, altText: "placeholder", key: 5 },
  { source: media_06_scrolling, altText: "placeholder", key: 6 },
  { source: { lowRes: media_07_desk, hiRes: media_07_desk }, altText: "placeholder", key: 7 },
  { source: { lowRes: media_08_plant, hiRes: media_08_plant }, altText: "placeholder", key: 8 },
]

export default carouselMedia
