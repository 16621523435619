import stack from './stack-logo.svg'
import grinAndBear from './grin-and-bear-logo.svg'
import codsallDriveways from './codsall-driveways-logo.svg'
import llBean from './l-l-bean-logo.svg'
import whim from './whim-logo.svg'
import igwhf from './igwhf-logo.svg'
import mrGreenside from './mr-greenside-logo.svg'
import tov from './tov-logo.svg'

export const logos: Array<Object> = [
  { svg: stack, alt: "The Stack Project's Logo", key: 1 },
  { svg: grinAndBear, alt: "Grin & Bear London's Logo", key: 2 },
  { svg: codsallDriveways, alt: 'The Codsall Driveways Logo', key: 3 },
  { svg: llBean, alt: 'The L.L. Bean Logo', key: 4 },
  { svg: whim, alt: 'The Whim Logo', key: 5 },
  {
    svg: igwhf,
    alt: "The Ironbridge Gorge World Heritage Festival's Logo",
    key: 6,
  },
  { svg: mrGreenside, alt: 'The Mr. Greenside Logo', key: 7 },
  { svg: tov, alt: "The Old Vicarage's Logo", key: 8 },
]
